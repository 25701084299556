<template>
    <div id="app" class="w-full h-full">
        <Loader :loading="mainLoading" v-if="mainLoading" fullscreen target="body" lock/>
        <Admin v-else-if="isLoggedIn"/>
    </div>
</template>

<script>

import Loader from "@/components/ui/Loader.vue";
import Admin from "@/views/Admin.vue";

import {mapActions, mapMutations, mapState} from 'vuex';
import {MAIN_LOADING} from "@/store/store";

export default {
    name: 'App',
    props: ["keycloak"],
    components: {
        Admin,
        Loader
    },
    computed: {
        ...mapState(['isLoggedIn', "mainLoading"])
    },
    methods: {
        ...mapMutations({
            mainLoadingFn: MAIN_LOADING
        }),
        ...mapActions(["initProfile"])
    },
    created() {
        this.mainLoadingFn(true)
        this.initProfile(this.keycloak);
    }
}
</script>